// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")

require("jquery")
require("moment")
require("moment/locale/ja")
require("fullcalendar")
require("bootstrap4-datetimepicker")
//require("tempusdominus-bootstrap-4")
//require("i18n")
require("bootstrap")
require("xlsx-populate")
require("papaparse")
require("dexie")
require("slick-carousel")
require("select2")
require("encoding-japanese")

require("packs/header")
require("packs/user")
require("packs/user_menu")
require("packs/datetimepicker")
require("packs/calendar_base_params")
require("packs/closed_day_calendar")
require("packs/price_calendar")
require("packs/price_calendar_readonly")
require("packs/reservation_calendar")
require("packs/optional_price")
require("packs/preview_image")
require("packs/mail_template")
require("packs/excel")
require("packs/slideshow")
require("packs/slick")
require("packs/reservation")
require("packs/object_assign")
require("packs/select2")
require("packs/promise")
require("packs/room")
require("packs/fixture")
require("packs/raffle_calendar")

require("packs/common")

require("packs/facility_room_select")
require("packs/facility")
